import { useGlobalStore } from "@/store/global";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let nextUrl = null;

const SUPERADMIN = 1;
const COMPANY = 2;

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      meta: {
        requiresAuth: false,
      },
      redirect: {
        path: "/",
      },
    },
    {
      path: "/",
      alias: "/main",
      name: "main",
      meta: {
        requiresAuth: true,
        requiresRoles: [SUPERADMIN, COMPANY],
      },
      component: () => import("../views/pages/main"),
    },
    {
      path: "/login",
      name: "login",
      meta: {
        requiresAuth: false,
      },
      component: () => import("../views/pages/login"),
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      meta: {
        requiresAuth: false,
      },
      component: () => import("../views/pages/forgot-password"),
    },

    // Admin pages
    {
      path: "/admin/companies",
      name: "admin-companies",
      meta: {
        requiresAuth: true,
        requiresRoles: [SUPERADMIN],
      },
      component: () => import("../views/pages/admin/companies"),
    },
    {
      path: "/admin/users",
      name: "admin-users",
      meta: {
        requiresAuth: true,
        requiresRoles: [SUPERADMIN],
      },
      component: () => import("../components/page-users"),
    },
    // {
    //   path: "/admin/users/:id",
    //   name: "admin-user",
    //   meta: {
    //     requiresAuth: true,
    //     requiresRoles: [SUPERADMIN],
    //   },
    //   component: () => import("../components/page-user"),
    // },
    // {
    //   path: "/admin/leaderboard",
    //   name: "admin-leaderboard",
    //   meta: {
    //     requiresAuth: true,
    //     requiresRoles: [SUPERADMIN],
    //   },
    //   component: () => import("../components/page-leaderboard"),
    // },
    // {
    //   path: "/admin/stats",
    //   name: "admin-stats",
    //   meta: {
    //     requiresAuth: true,
    //     requiresRoles: [SUPERADMIN],
    //   },
    //   component: () => import("../components/page-stats"),
    // },

    // Company pages
    {
      path: "/company/info",
      name: "company-info",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/info"),
    },
    {
      path: "/company/setting",
      name: "company-setting",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/setting"),
    },
    {
      path: "/company/news",
      name: "company-news",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/news"),
    },
    {
      path: "/company/news/create",
      name: "company-news-create",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/news/news"),
    },
    {
      path: "/company/news/:id",
      name: "company-news-edit",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/news/news"),
    },
    {
      path: "/company/cafes",
      name: "company-cafes",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/cafes"),
    },
    {
      path: "/company/cafes/create",
      name: "company-cafes-create",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/cafes/cafes"),
    },
    {
      path: "/company/cafes/:id",
      name: "company-cafes-edit",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../views/pages/company/cafes/cafes"),
    },
    {
      path: "/company/users",
      name: "company-users",
      meta: {
        requiresAuth: true,
        requiresRoles: [COMPANY],
      },
      component: () => import("../components/page-users"),
    },
    // {
    //   path: "/company/users/:id",
    //   name: "company-user",
    //   meta: {
    //     requiresAuth: true,
    //     requiresRoles: [COMPANY],
    //   },
    //   component: () => import("../components/page-user"),
    // },
  ],
});

// Fix, silence NavigationDuplicated error
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

// Route guard checks to see if you are logged in
// insure your are routed back to requested url after login
router.beforeEach((to, from, next) => {
  // console.log('to: ', to)

  const store = useGlobalStore();

  // if logout page - no need req user data, go to login page
  if (to.name === "login") {
    next();
  } else if (to.name === "logout") {
    store
      .logout()
      .then((response) => {
        next("/login");
      })
      .catch((error) => {});
  } else {
    // console.log('to: ', to)
    // console.log('from: ', from)

    // except on same page
    if (to.name === from.name) {
      next();
    } else {
      store
        .reload()
        .then((response) => {
          if (to.matched.some((record) => record.meta.requiresAuth)) {
            checkAuthAndRoles(to, from, next);
          } else {
            next();
          }
        })
        .catch((error) => {});
    }
  }

  // if (to.meta?.layout) {
  //   if (store.authorized) {
  //     store.setLayout(to.meta.layout)
  //   } else {
  //     store.setLayout(layouts.contenOnly)
  //   }
  // }
});

function checkAuthAndRoles(to, from, next) {
  console.log("checkAuthAndRoles...");

  const store = useGlobalStore();

  // Check Auth and Roles
  if (store.authorized) {
    // check requiresRoles and user.roles in requiresRoles
    if (to.meta.requiresRoles?.length) {
      console.log("Page require requiresRoles: ", to.meta.requiresRoles);
      let matchRoles = false;

      if (store.userRoles?.length) {
        matchRoles = store.userRoles.some((r) =>
          to.meta.requiresRoles.includes(r)
        );
      }

      console.log("store.userRoles: ", store.userRoles);
      console.log("to.meta.requiresRoles: ", to.meta.requiresRoles);

      if (!matchRoles) {
        console.log("Routing: User NOT has needed premission");
        const prevpage = from.path ? from.path : "/";
        next(prevpage);
        return;
      } else {
        console.log("Routing: User has needed premission");
      }
    } else {
      console.log("Routing: No req roles needed - common page");
    }

    if (nextUrl) {
      // case I have next url, I have been redirected
      const url = nextUrl;
      nextUrl = null;
      next(url);
      return;
    }

    // case no next : most situation
    next();
    return;
  } else {
    next("/login");
  }
}

// set Splashscreen
// router.afterEach((to, from) => {
//   setTimeout(() => {
//     store.commit('setSplashScreen', false)
//   }, 500)
// })

export default router;
