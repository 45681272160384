module.exports = {
  // ===== AUTH =====
  URL_LOGIN: "api/auth/admin/login", // post, email, password // const SUPERADMIN = 1; const COMPANY = 2;
  URL_LOGOUT: "api/auth/admin/logout", // post, auth (token)
  URL_PROFILE: "api/auth/admin/me", // get, auth (token)

  // ===== RESTORE PASS =====
  URL_GET_CODE: "api/reset-password/get-code", // post (phone)
  URL_VERIFY_CODE: "api/reset-password/verify-code", // post (phone, code)
  URL_SET_PASSWORD: "api/reset-password/set-password", // post (password, password_confirmation, jwt)

  // ===== CLIENT =====
  URL_CLIENT_LIST: "api/admin/users", // get, auth (token), params: 'company_ids%5B%5D', phone, pages, toggle, sort
  URL_CLIENT_LIST_COMPANY: "api/admin/users/users-for-company", // get, auth (token), params: pages, toggle, sort
  URL_CLIENT_DISCOUNT_UPDATE: "api/admin/users/custom-discount-update/", // post, auth (token), params: _method(patch), discount(integer)

  // ===== COMPANY =====
  URL_COMPANY_LIST: "api/admin/companies", // get, admin: query: pages, search, toggle, sort
  URL_COMPANY_INFO: "api/admin/companies", // create, edit
  URL_COMPANY_SETTING: "api/admin/companies/setting", // get, company:
  URL_COMPANY_SETTING_UPDATE: "api/admin/companies/setting-update", // post, company: _method: 'patch', discount_type, bonuses_per_cup (if bonuses_per_cup), writte_of_drink_price (if bonuses_per_cup), bonuses_for_writte_of (if bonuses_per_cup), count_of_cups (if count_of_cups), percents, prepayment_discount

  // ===== NEWS (posts) =====
  URL_NEWS: "api/admin/posts",
  // List, get, news list: pages, search
  // Create, post, create post: picture, name, text
  // Delete, api/admin/posts/destroy/58 - post: '/destroy/${id}' -  _method: 'delete', id
  // Update, api/admin/posts/61 - post: '/${id}' - _method: 'patch, picture, name, text
  URL_NEWS_DELETE: "api/admin/posts/arr-delete", // Delete arr, post, _method: 'delete', 55: 1, field name is id of elememt (input type checkbox)
  URL_NEWS_SORT: "api/admin/posts/sort-list", // Sort, post: sort: ["{\"sort\":\"42\",\"id\":\"61\"}","{\"sort\":\"40\",\"id\":\"56\"}"] - sprt it is string json

  // ===== CAFES (coffee_shops) =====
  URL_CAFES: "api/admin/coffee-shops",
  // List, get: query: pages, search, toggle, sort
  // Create, post: picture, address
  // Delete, api/admin/coffee-shops/destroy/79 - post: '/destroy/${id}' -  _method: 'delete', id
  // Update, api/admin/coffee-shops/80 - post: '/${id}' - _method: 'patch, picture, address
  URL_CAFES_DELETE: "api/admin/coffee-shops/arr-delete", // Delete arr, post, _method: 'delete', 55: 1, field name is id of elememt (input type checkbox)
};
