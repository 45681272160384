import axios from "@/plugins/axios";
import urls from "@/urls"; // api endpoints
import { defineStore } from "pinia";
import router from "@/router";

export const useGlobalStore = defineStore("global", {
  state: () => ({
    lang: "ua",
    menuOpen: false,
    token: "",
    user: null,
  }),
  getters: {
    userRoles: (state) => state.user?.roles || [],
    authorized: (state) => !!state.token,
  },
  actions: {
    // setLang(payload) {
    //   this.lang = payload
    // },
    setMenuOpen(payload) {
      this.menuOpen = payload;
    },
    setToken(payload) {
      this.token = payload;
    },
    setUser(payload) {
      this.user = payload;
    },
    setLogout() {
      this.token = "";
      this.user = null;
    },

    async logout() {
      if (this.authorized) {
        try {
          await axios.post(urls.URL_LOGOUT);
        } catch (error) {}
      }

      this.setLogout();
      router.push("/login").catch(() => {});
    },

    async login({ email, password }) {
      try {
        const response = await axios.post(urls.URL_LOGIN, { email, password });
        console.log("A login, success, response: ", response);
        this.setToken(response.data.token);
        this.setUser({
          ...response.data.user,
          roles: [response.data.user.role],
        }); // add roles to user data

        return {
          ok: true,
        };
      } catch (error) {
        console.log("A login, error: ", error);
        await this.logout();
        return error;
      }
    },

    async reload() {
      console.log("A reload...");
      // await this.dispatch("fetchSysOpt"); // fire any reload

      if (this.authorized) {
        await this.getUserData();
      }
    },

    async getUserData() {
      try {
        const { data } = await axios.get(urls.URL_PROFILE);
        console.log("A getUserData, success, data: ", data);

        // if user.block - logout // 0 - normal, 1 - blocked
        // if (data?.user?.block) {
        //   // console.log('User blocked - logout...')
        //   await this.logout()
        // }

        this.setUser({ ...data.user, roles: [data.user.role] }); // add roles to user data
      } catch (error) {
        console.log("A getUserData, error: ", error);
        await this.logout();
      }
    },

    // pages, search, toggle, sort
    async getCompanyList(
      params = {
        pages: 25,
        page: 1, // ???
        search: "",
        toggle: "",
        sort: "",
      }
    ) {
      console.log("getCompanyList, params: ", params);

      return await axios
        .get(urls.URL_COMPANY_LIST, {
          headers: {
            lang: this.lang,
          },
          params: { ...params },
        })
        .then((response) => {
          console.log("getCompanyList, response: ", response);
          return response;
        })
        .catch((error) => {
          console.log("getCompanyList, error: ", error);
          return error;
        });
    },

    async getCompanySetting() {
      console.log("getCompanySetting");
      return await axios.get(urls.URL_COMPANY_SETTING, {
        headers: {
          lang: this.lang,
        },
      });
    },

    // post, company: _method: 'patch', discount_type, bonuses_per_cup (if bonuses_per_cup), writte_of_drink_price (if bonuses_per_cup), bonuses_for_writte_of (if bonuses_per_cup), count_of_cups (if count_of_cups), percents, prepayment_discount
    async setCompanySetting(data = null) {
      console.log("setCompanySetting, data: ", data);

      return await axios({
        method: "post",
        url: urls.URL_COMPANY_SETTING_UPDATE,
        data: {
          _method: "patch",
          ...data,
        },
        headers: {
          lang: this.lang,
        },
      });
    },

    async createCompany(data) {
      // name, fio, phone, email, password,
      console.log("createCompany, data: ", data);

      return await axios({
        method: "post",
        url: urls.URL_COMPANY_INFO,
        data,
        headers: {
          lang: this.lang,
        },
      })
        .then((response) => {
          console.log("createCompany, response: ", response);
          return response;
        })
        .catch((error) => {
          console.log("createCompany, error: ", error);
          return error;
        });
    },

    async editCompany(data) {
      // _method: "patch", name, representative_name, contact, login, email, password, password_confirmation
      console.log("editCompany, data: ", data);

      if (!data.id) {
        return;
      }

      return await axios({
        method: "post",
        url: urls.URL_COMPANY_INFO + "/" + data.id,
        data: {
          _method: "patch",
          ...data,
        },
        headers: {
          lang: this.lang,
        },
      })
        .then((response) => {
          console.log("editCompany, response: ", response);
          return response;
        })
        .catch((error) => {
          console.log("editCompany, error: ", error);
          return error;
        });
    },

    // URL_CLIENT_LIST: "api/admin/users", // get, auth (token), params: 'company_ids%5B%5D', phone, pages, toggle, sort
    // URL_CLIENT_LIST_COMPANY: "api/admin/users/users-for-company", // get, auth (token), params: pages, toggle, sort
    async getClientList(
      params = {
        role: "",
        pages: 25,
        page: 1,
        // "company_ids%5B%5D": "",
        // company_ids: "",
        phone: "",
        toggle: "",
        sort: "",
      }
    ) {
      console.log("getClientList, params: ", params);

      let url = urls.URL_CLIENT_LIST_COMPANY;
      if (params.role === "admin") {
        url = urls.URL_CLIENT_LIST;
      }

      return await axios
        .get(url, {
          headers: {
            lang: this.lang,
          },
          params: { ...params },
        })
        .then((response) => {
          console.log("getClientList, response: ", response);
          return response;
        })
        .catch((error) => {
          console.log("getClientList, error: ", error);
          return error;
        });
    },

    async clientDiscount(data) {
      // post, _method(patch), discount(integer)
      console.log("clientDiscount, data: ", data);

      return await axios({
        method: "post",
        url: urls.URL_CLIENT_DISCOUNT_UPDATE + data.id,
        data: {
          _method: "patch",
          discount: data.discount,
        },
        headers: {
          lang: this.lang,
        },
      })
        .then((response) => {
          console.log("clientDiscount, response: ", response);
          return response;
        })
        .catch((error) => {
          console.log("clientDiscount, error: ", error);
          throw error;
        });
    },

    // ===== NEWS (posts) =====

    // news list: pages, search
    async getNewsList(
      params = {
        pages: 25,
        page: 1,
        search: "",
      }
    ) {
      console.log("getNewsList, params: ", params);

      return await axios.get(urls.URL_NEWS, {
        headers: {
          lang: this.lang,
        },
        params: { ...params },
      });
    },

    // Get news: /api/admin/posts/61/edit
    async getNews(id) {
      console.log("getNews, id: ", id);
      return await axios({
        method: "get",
        url: urls.URL_NEWS + `/${id}/edit`,
        data: { id },
        headers: {
          lang: this.lang,
        },
      });
    },

    // Create news: picture, name, text
    async createNews(formData) {
      // console.log("createNews, data: ", data);

      // Display the key/value pairs ###debug
      for (var pair of formData.entries()) {
        console.log(pair[0] + " :", pair[1]);
      }

      return await axios({
        method: "post",
        url: urls.URL_NEWS,
        data: formData,
        headers: {
          lang: this.lang,
          "content-type": "multipart/form-data",
        },
      });
    },

    // Delete news, post: '/destroy/${id}' -  _method: 'delete', id
    async deleteNews(id) {
      console.log("deleteNews, id: ", id);

      return await axios({
        method: "post",
        url: urls.URL_NEWS + `/destroy/${id}`,
        data: {
          _method: "delete",
          id,
        },
        headers: {
          lang: this.lang,
        },
      });
    },

    // Update news, post: '/${id}' - _method: 'patch, picture, name, text
    async updateNews(id, formData) {
      // console.log("updateNews, data: ", data);
      formData.append("_method", "patch");

      // Display the key/value pairs ###debug
      for (var pair of formData.entries()) {
        console.log(pair[0] + " :", pair[1]);
      }

      return await axios({
        method: "post",
        url: urls.URL_NEWS + `/${id}`,
        data: formData,
        headers: {
          lang: this.lang,
          "content-type": "multipart/form-data",
        },
      });
    },

    // URL_NEWS_DELETE: "api/admin/posts/arr-delete", // Delete arr, post, _method: 'delete', 55: 1, field name is id of elememt (input type checkbox)
    async deleteNewsArr(arr) {
      // console.log("deleteNewsArr, arr: ", arr);

      const data = {
        _method: "delete",
      };

      arr.map((id) => {
        data[id.id] = 1;
      });

      // console.log("deleteNewsArr, data: ", data);

      return await axios({
        method: "post",
        url: urls.URL_NEWS_DELETE,
        data,
        headers: {
          lang: this.lang,
        },
      });
    },

    // URL_NEWS_SORT: "api/admin/posts/sort-list", // Sort, post: sort: ["{\"sort\":\"42\",\"id\":\"61\"}","{\"sort\":\"40\",\"id\":\"56\"}"] - sprt it is string json
    async sortNews(sorted) {
      // console.log("sortNews, sort: ", sort);

      const sort = [];
      sorted.map((item) => {
        sort.push(JSON.stringify(item));
      });

      return await axios({
        method: "post",
        url: urls.URL_NEWS_SORT,
        data: {
          sort: JSON.stringify(sort),
        },
        headers: {
          lang: this.lang,
        },
      });
    },

    // ===== CAFES (coffee_shops) =====
    // cafes list: pages, search
    async getCafesList(
      params = {
        pages: 25,
        page: 1,
        search: "",
        toggle: "",
        sort: "",
      }
    ) {
      console.log("getCafesList, params: ", params);

      return await axios.get(urls.URL_CAFES, {
        headers: {
          lang: this.lang,
        },
        params: { ...params },
      });
    },

    // Get cafes: /api/admin/posts/61/edit
    async getCafes(id) {
      console.log("getCafes, id: ", id);
      return await axios({
        method: "get",
        url: urls.URL_CAFES + `/${id}/edit`,
        data: { id },
        headers: {
          lang: this.lang,
        },
      });
    },

    // Create cafes: picture, address
    async createCafes(formData) {
      // console.log("createCafes, data: ", data);

      // Display the key/value pairs ###debug
      for (var pair of formData.entries()) {
        console.log(pair[0] + " :", pair[1]);
      }

      return await axios({
        method: "post",
        url: urls.URL_CAFES,
        data: formData,
        headers: {
          lang: this.lang,
          "content-type": "multipart/form-data",
        },
      });
    },

    // Delete cafes, post: '/destroy/${id}' -  _method: 'delete', id
    async deleteCafes(id) {
      console.log("deleteCafes, id: ", id);

      return await axios({
        method: "post",
        url: urls.URL_CAFES + `/destroy/${id}`,
        data: {
          _method: "delete",
          id,
        },
        headers: {
          lang: this.lang,
        },
      });
    },

    // Update cafes, post: '/${id}' - _method: 'patch, picture, address
    async updateCafes(id, formData) {
      // console.log("updateCafes, data: ", data);
      formData.append("_method", "patch");

      // Display the key/value pairs ###debug
      for (var pair of formData.entries()) {
        console.log(pair[0] + " :", pair[1]);
      }

      return await axios({
        method: "post",
        url: urls.URL_CAFES + `/${id}`,
        data: formData,
        headers: {
          lang: this.lang,
          "content-type": "multipart/form-data",
        },
      });
    },

    // URL_CAFES_DELETE: "api/admin/posts/arr-delete", // Delete arr, post, _method: 'delete', 55: 1, field name is id of elememt (input type checkbox)
    async deleteCafesArr(arr) {
      // console.log("deleteCafesArr, arr: ", arr);

      const data = {
        _method: "delete",
      };

      arr.map((id) => {
        data[id.id] = 1;
      });

      // console.log("deleteCafesArr, data: ", data);

      return await axios({
        method: "post",
        url: urls.URL_CAFES_DELETE,
        data,
        headers: {
          lang: this.lang,
        },
      });
    },

    // ===== PASS (reset_password) =====
    // TODO
  },
  persist: [
    {
      storage: localStorage,
      paths: ["lang", "menuOpen"],
    },
    {
      storage: sessionStorage,
      paths: ["token"],
    },
  ],
});
