import Vue from "vue";
import App from "./App.vue";
import Vuelidate from "vuelidate";
// import VueTour from "vue-tour";
import vco from "v-click-outside";
import "@/plugins/axios";
import VueCompositionAPI from "@vue/composition-api";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(pinia);

import router from "@/router";

// import VueApexCharts from "vue-apexcharts";
import Debuginfo from "@/components/Debuginfo.vue"; // ###debug
import Meta from "vue-meta";

import urls from "@/urls";
import helpers from "@/helpers";

// import "./directives/click-outside"

import ua from "./locales/ua.json";
import en from "./locales/en.json";
import VueI18n from "vue-i18n";

Vue.config.productionTip = false;

Vue.use(vco);

// As a plugin
import VueMask from "v-mask";
Vue.use(VueMask);
Vue.use(VueCompositionAPI);

// Vue.component("apexchart", VueApexCharts);
Vue.component("Debuginfo", Debuginfo);

// All BootstrapVue
// import BootstrapVue from "bootstrap-vue";
// Vue.use(BootstrapVue);

// BootstrapVue Individual components and directives
import {
  BModal,
  ModalPlugin,
  BTable,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BPagination,
  BAlert,
  BFormInput,
  BSpinner,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BFormCheckbox,
} from "bootstrap-vue";
Vue.component("BModal", BModal);
Vue.component("BTable", BTable);
Vue.component("BTableSimple", BTableSimple);
Vue.component("BThead", BThead);
Vue.component("BTbody", BTbody);
Vue.component("BTr", BTr);
Vue.component("BTh", BTh);
Vue.component("BTd", BTd);
Vue.component("BPagination", BPagination);
Vue.component("BAlert", BAlert);
Vue.component("BFormInput", BFormInput);
Vue.component("BSpinner", BSpinner);
Vue.component("BFormSelect", BFormSelect);
Vue.component("BFormSelectOption", BFormSelectOption);
Vue.component("BFormSelectOptionGroup", BFormSelectOptionGroup);
Vue.component("BFormCheckbox", BFormCheckbox);
// Vue.directive('b-modal', VBModal)
Vue.use(ModalPlugin);

Vue.use(Vuelidate);
// Vue.use(VueTour);
Vue.use(Meta);
Vue.use(VueI18n);

Vue.prototype.$urls = urls;
Vue.prototype.$helpers = helpers;

const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE || "ua",
  messages: { ua, en },
});

import "@/assets/scss/app.scss";

new Vue({
  pinia,
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
